@import "rsuite/dist/rsuite.css";

.ant-select-selector{
    border-radius: 5px!important;
}

.rs-nav-item-active{
    color: #1B6B50!important;
}
.schedule {
    margin-top: 100px;


}
.schedule .box {
    border: 1px solid rgba(128, 128, 128, 0.4);
    width: 100%;
    margin-top: 25px;
}
.schedule .box .active{
    background-color: #1B6B50;
    color: white!important;
}

.schedule .box .week{
    padding: 20px;
    writing-mode: vertical-lr;
    rotate: 180deg;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1b6b50;
    color: #1B6B50;
    width: 50px;
}
.schedule .box .content{
    width: calc(100% - 50px);
    font-size: 16px;
}
.schedule .box .content .table{
    margin-bottom: 0;
    height: 100% !important;
}


@media screen and (max-width: 992px) {
    .schedule {
        margin-top: 25px;
    }
    .rs-nav-item{
        font-size: 16px!important;
    }
}
@media screen and (max-width: 664px) {

    .schedule .box {
        display: block!important;
        margin-top: 15px;
    }
    .navbarstayle{
        margin-bottom: 30px!important;
    }
    .schedule .box .week{
        padding: 10px;
        writing-mode: horizontal-tb;
        rotate: 0deg;
        width: 100%;
    }
    .schedule .box .content{
        width: 100% ;
        font-size: 12px;
        overflow-x: scroll;
        padding-bottom: 0!important;

    }


}
