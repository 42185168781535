.mesengers {
  height: 100%;
  display: flex;
  align-items:center;
}
.mesengers img {
  width: 32px !important;
  height: 32px !important;
  margin-right: 20px;
}

@media screen and (max-width: 1512px){
    .mesengers img {
        width: 27px !important;
        height: 27px !important;
        margin-right: 15px;
    }
}
@media screen and (max-width: 768px){
    .mesengers img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 10px;
    }
}