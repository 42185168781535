@import url('https://fonts.googleapis.com/css2?family=Rubik+Distressed&display=swap');
html,
body {

    scroll-behavior: smooth;
    transition: 0.3s;
    overflow-x: hidden;
    font-size: 20px;
}


.newyearBox{
    top: 0;
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-between;
    background-image: url("../newYear/giphy.gif");
}
.newyearBox img{
    width: 430px;
    height: 90% !important;
}
.newyearBox img:nth-child(2){
    transform: scaleX(-1);
}

body.zoom {
    zoom: 1.1;
}

body.black__and__white {
    filter: grayscale(100%);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

a {
    text-decoration: none!important;
    color: black;
}

.container {
    padding: 0 140px !important;
    max-width: 1920px;
    margin: 0 auto;
    background-size: 90% 90%;
}


.pt-pages {
    padding-top: 100px;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #1B6B50;
}

.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    color: #1B6B50;
    margin: 25vh auto;
    display: block;
  }
    .loader:before,
    .loader:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s spin linear infinite;
    }
    .loader:after {
      color: #FF3D00;
      transform: rotateY(70deg);
      animation-delay: .4s;
    }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }

  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }

@media screen and (max-width: 1512px) {
    .newyearBox img{
        width: 360px;
        height: 50%;
    }
    .container {
        padding: 0 100px !important;
    }
}

@media screen and (max-width: 992px) {
    .newyearBox{
        height: 250px;
    }
    .newyearBox img{
       display: none;
    }
    .container {
        padding: 0 60px !important;
    }
    .loader {
        width: 300px;
        height: 300px;  
        margin: 25vh auto;
        
      }
}

@media screen and (max-width: 780px) {
    .newyearBox{
        height: 200px;
    }
    .container {
        padding: 0 16px !important;
    }
    .loader {
        width: 150px;
        height: 150px;  
        margin: 25vh auto;
        
      }
}


   